import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { PostCardHalf, Layout, Authors, ImageHeader, TagHelperArray } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL, BodyText, H5, H3, HeaderCursive, HeaderCursiveSmall, Koenig} from "../utils/typography"

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location }) => {
    const page = data.ghostPage

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Layout>
              <ImageHeader title={page.title} excerpt={page.excerpt} image={page.localFeatureImage}/>
              <CenteredContent>
                  <PostContent>
                          {/* The main post content */ }
                      <PostStyled
                          dangerouslySetInnerHTML={{ __html: page.html }}
                      />

                  </PostContent>
                </CenteredContent>
            </Layout>
        </>
    )
}

const PostStyled = styled("div")`

  p {
    margin: 2em 0;
    color: ${config.textColorGrey};
    text-align: justify;
    ${BodyText};
  }

  h1 {
    margin: 2rem 0;
    color: ${config.textColorDark};
    ${HeaderCursive};
  }

  h2 {
    margin: 2rem 0rem;
    color: ${config.textColorDark};
    ${HeaderCursiveSmall};
  }

  h3 {
    margin: 2rem, 0rem;
    color: ${config.textColorDark};
    ${H3};
  }
  h4 {
    margin: 2rem 0rem;
    color: ${config.textColorGrey};
    ${H3};
  }
  h5 {
    margin: 2rem 0rem;
    color: ${config.textColorDark};
    ${H5};
  }
  h6 {
    margin: 2rem 0rem;
    color: ${config.textColorGrey};
    ${H5};
  }

  h7 {
    margin: 2rem 0rem;
    color: ${config.textColorGrey};
    ${BodyText};
    font-weight: 700;
  }

  label{
    ${H3};
  }

  input[type="submit"] {
    ${H3};
    border: 4px solid ${config.color1} !important;
    transition: border-color 0.5s, color 0.5s;

    &:hover{
      border-color: ${config.textColorDark} !important;
      color: ${config.textColorHighlight} !important;
    }
  }

  blockquote{
    ${BodyText};
  }

  figcaption{
    ${BodyText};
  }

  ul {
    list-style: none; /* Remove default bullets */
    ${BodyText};
  }

  li {
    font-size: 1.1em;
  }

  ul {
    li::before {
    content: "\u2022";
    color: ${config.color2};
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    }
  }

  a{
    text-decoration: none;
    color: ${config.color2};
    ${BodyText};
    font-weight: 700;

    &:active{
      color: ${config.color3};
    }

    &:hover{
      text-decoration: underline;
    }
  }

  ${Koenig};
`

const PostContent = styled("div")`
  margin: 2em 0 5em 0;

`

const IndexContainer = styled("div")`
  margin: 10rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.225em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
    }
`

const CenteredContent = styled("div")`
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 90%;
  min-height: 50vh;

  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

const Container = styled("div")`
  z-index: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Page

export const postQuery = graphql`
    query($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            ...GhostPageFields
        }
    }
`
